import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

export default function SEO({ description, meta, title }) {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          description
          image
          keywords
          siteUrl
          title
        }
      }
    }
  `);
  const image = data.site.siteMetadata.image;
  const keywords = data.site.siteMetadata.keywords;
  const metaDescription = description || data.site.siteMetadata.description;
  const defaultTitle = data.site.siteMetadata?.title;
  return (
    <Helmet
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: "google-site-verification",
          content: "MsTtvuzoTcQYx-fG2CiwFUNzrdz8fMDH1V41HBYMr9s",
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: keywords,
        },
        {
          property: `og:title`,
          content: defaultTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          name: `twitter:image`,
          content: image,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: defaultTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
};
